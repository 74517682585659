const initialState = {
  chats: [],
  userProfile: {},
  selectedUser: {},
  browserType: 'chat',
  contacts: [],
  total: 1,
  params: {}
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_USER_PROFILE':
      return { ...state, userProfile: action.userProfile.userData }
    case 'GET_CHATS':
      return { ...state, chats: action.data }
    case 'GET_CONTACTS':
      return {
        ...state,
        contacts: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_CONVERSATION':

      // ** Add new msg to chat
      const user =  {...state.selectedUser}
      user.last_conversation.messages = action.conversation.messages.concat(user.last_conversation.messages)

      return { ...state, selectedUser: {...user} }

    case 'SELECT_CHAT':
      
      if (!action.data.contact.last_conversation) {
        action.data.contact.last_conversation = action.data.contact.last_conversation_closed
      }
      action.data.contact.pageConversation = 2

      return { ...state, selectedUser: action.data.contact, browserType: action.browserType}
    case 'SEND_MSG':
      // ** Add new msg to chat
      // const newMsg = action.data.chat

      // const selectedUserNew =  {...state.selectedUser}
      // selectedUserNew.last_conversation.messages.push(newMsg)
      // selectedUserNew.last_message = newMsg

      // return { ...state, selectedUser: {...selectedUserNew} }
      return { ...state}
      // return { ...state, selectedUser: { ...state.selectedUser, chat: newMsg } }
    default:
      return state
  }
}

export default chatReducer
