// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'

import departments from '@src/views/apps/department/store/reducer'
import queues from '@src/views/apps/queue/store/reducer'
import shifts from '@src/views/apps/shift/store/reducer'
import agents from '@src/views/apps/agent/store/reducer'
import dashboard from '@src/views/dashboard/ecommerce/store/reducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  
  departments,
  queues,
  shifts,
  agents,
  dashboard
})

export default rootReducer
