// import { useDispatch } from 'react-redux'
import React from 'react'
import axios from 'axios'

export const buildFormData = (formData, data, parentKey) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
    } else {
        const value = data === null ? '' : data
        formData.append(parentKey, value)
    }
}

export const handleRes = (response) => {


}

export const APIUrl = () => {

    const url = `${window.location.protocol}//${window.location.hostname}:${process.env.REACT_APP_API_PORT}`
    return {
        base: url,
        v1: `${url}/api/v1`
    }
    
}

export const authClient = axios.create({
  baseURL: APIUrl().base,
  withCredentials: true // required to handle the CSRF token
})

export const handleError = (error) => {

    if (error.response && error.response.status === 404) {

        return [<p>Not Found</p>]
    } else if (error.response && error.response.data) {

        if (error.response.data.message && error.response.data.message === "Unauthenticated.") {

            localStorage.removeItem('userData')
            window.location.reload()
        } else if (error.response.data.errors) {

            const errors = [] 
            errors[0] = error.response.data.message
            Object.values(error.response.data.errors).map((e, i) => {
                if (e[0] !== "" && e[0] !== " ") {
                errors.push(<p key={i}>{e[0]}</p>)
                }
            })

            return errors
        }
    }

    return []
}

export const validURL = (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

    return !!pattern.test(str)
  }

export const currentOrganization = async (themeConfig) => {

    await authClient.get(`${APIUrl().v1}/current-organization`).then(response => {
        // handleRes(response)
        themeConfig.app.appName = response.data.row.name
        themeConfig.app.appLogoImage = response.data.row.image

        // return themeConfig
    }).catch(error => {
        // handleError(error)
    })

    return themeConfig
}
export const dateDifference = (startDate, endDate) => {
    // Calculate time between two dates:
    const date1 = startDate // the date you already commented/ posted
    const date2 = endDate // today
    let r = {} // object for clarity
    let message
    const diffInSeconds = Math.abs(date2 - date1) / 1000
    const days = Math.floor(diffInSeconds / 60 / 60 / 24)
    const hours = Math.floor(diffInSeconds / 60 / 60 % 24)
    const minutes = Math.floor(diffInSeconds / 60 % 60)
    const seconds = Math.floor(diffInSeconds % 60)
    const milliseconds = Math.round((diffInSeconds - Math.floor(diffInSeconds)) * 1000)
    const weeks = Math.floor(days / 7)
    const months = Math.floor(days / 30)
    const years = Math.floor(months / 12)
  
    /* Below object is useful if you want to show difference in detailed context */
    // if you want to return an object instead of a message
    r = {years, months, weeks, days, hours, minutes, seconds, milliseconds}
  
    /* If you want to display difference either in any one of the data in above object 'r'.*/
  
    // check if difference is in years or months
    if (years === 0 && months === 0) {
      // show in weeks if no years / months
      if (weeks > 0) {
        if (weeks === 1) {
          message = `${weeks} week`
        } else {
          message = `${weeks} weeks`
        }
      } else if (days > 0) {
        if (days === 1) {
          message = `${days} day`
        } else { 
          message = `${days} days`
        }
      } else if (hours > 0) {
  
        // show in hours if no years / months / days
        if (hours === 1) {
          message = `${hours} hour`
        } else {
          message = `${hours} hours`
        }
      } else {
        // show in minutes if no years / months / days / hours
        if (minutes === 1) {
          message = `${minutes} minute`
        } else { 
          message = `${minutes} minutes`
          
        }
      }
    } else if (years === 0 && months > 0) {
      // show in months if no years
      if (months === 1) {
        message = `${months} month`
      } else {
        message = `${months} months`
  
      }
    } else if (years > 0) {
      // show in years if years exist
      if (years === 1) {
        message = `${years} year`
      } else {
        message = `${years} years`
  
      }
    }
    return message
  }
