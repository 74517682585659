// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedDepartment: null
}

const departments = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DEPARTMENT':
      return { ...state, selectedDepartment: action.selectedDepartment }
    case 'ADD_DEPARTMENT':
      return { ...state }
    case 'EDIT_DEPARTMENT':
      return { ...state }
    case 'DELETE_DEPARTMENT':
      return { ...state }
    case 'LIST_DEPARTMENT':
      return { ...state, list: action.list }
    default:
      return { ...state }
  }
}
export default departments
