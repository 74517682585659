const initialState = {
  dashboardStatistics: {}
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DASHBOARD_STATISTICS':
      return { ...state, dashboardStatistics: action.dashboardStatistics }
    default:
      return state
  }
}

export default chatReducer
